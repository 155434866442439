import React from "react";
import Roadbanner from "../../Component/ProductFeatures/Roadblocker/Roadbanner";
import Road from "../../Component/ProductFeatures/Roadblocker/Road";
const Anprpagelayout = () => {
  return (
    <div>
      <Roadbanner />
      <Road />
    </div>
  );
};

export default Anprpagelayout;
