import React from "react";

import Baggagebanner from "../../Component/ProductFeatures/BaggageScanner/Baggagebanner";
import Baggagescanner from "../../Component/ProductFeatures/BaggageScanner/Baggagescanner";

const Baggagescannerpagelayout = () => {
  return (
    <div>
      <Baggagebanner />
      <Baggagescanner />
    </div>
  );
};
export default Baggagescannerpagelayout;
