import React from "react";
import Productfeatures from "../../Component/ProductFeatures/Productfeatures";
import VegaBaanner from "../../Component/ProductFeatures/VegaBaanner";
const ProductPageLayOut = () => {
  return (
    <>
      <VegaBaanner />
      <Productfeatures />
    </>
  );
};
export default ProductPageLayOut;
