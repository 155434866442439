import React from "react";
import Tripodbanner_ from "../../Component/ProductFeatures/TripodTurnstile II/Tripodbanner_";
import Tripodturnstile2 from "../../Component/ProductFeatures/TripodTurnstile II/Tripodturnstile2";
const TripodeTurnstileLayout = () => {
  return (
    <div>
      <Tripodbanner_ />
      <Tripodturnstile2 />
    </div>
  );
};
export default TripodeTurnstileLayout;
