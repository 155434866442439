import React from "react";

import Fullheightturnstile from "../../Component/ProductFeatures/FullHeightTurnstile/Fullheightturnstile";
import Fullheightturnstilebanner from "../../Component/ProductFeatures/FullHeightTurnstile/Fullheightturnstilebanner";
const Fullheightturnstilepagelayout = () => {
  return (
    <div>
      <Fullheightturnstilebanner />
      <Fullheightturnstile />
    </div>
  );
};
export default Fullheightturnstilepagelayout;
