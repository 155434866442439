// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.case-study-header-main {
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 2rem;
    padding-right: 2rem;
}

.case-study-header-text {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 700;
    background-image: linear-gradient(#058AFB, #01106b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.case-study-sub-header-text {
    font-family: Arial, 'Fira Sans', sans-serif;
    text-align: center;
    font-weight: 700;
    /* background-image: linear-gradient(#058AFB, #01106b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.case-study-sub-header-text span {
    background-image: linear-gradient(#058AFB, #01106b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Component/CaseStudy/CaseStudyHeader/CaseStudyHeader.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yCAAyC;IACzC,kBAAkB;IAClB,gBAAgB;IAChB,mDAAmD;IACnD,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;IACI,2CAA2C;IAC3C,kBAAkB;IAClB,gBAAgB;IAChB;;2CAEuC;AAC3C;;AAEA;IACI,mDAAmD;IACnD,6BAA6B;IAC7B,oCAAoC;AACxC","sourcesContent":[".case-study-header-main {\n    font-family: Arial, Helvetica, sans-serif;\n    padding-left: 2rem;\n    padding-right: 2rem;\n}\n\n.case-study-header-text {\n    font-family: Arial, Helvetica, sans-serif;\n    text-align: center;\n    font-weight: 700;\n    background-image: linear-gradient(#058AFB, #01106b);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}\n\n.case-study-sub-header-text {\n    font-family: Arial, 'Fira Sans', sans-serif;\n    text-align: center;\n    font-weight: 700;\n    /* background-image: linear-gradient(#058AFB, #01106b);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent; */\n}\n\n.case-study-sub-header-text span {\n    background-image: linear-gradient(#058AFB, #01106b);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
