import React from "react";
import Sliderflapbanner from "../../Component/ProductFeatures/Sliderflap/Sliderflapbanner_";
import Sliderflap from "../../Component/ProductFeatures/Sliderflap/Sliderflap";
const Sliderflappagelayout = () => {
  return (
    <div>
      <Sliderflapbanner />
      <Sliderflap />
    </div>
  );
};

export default Sliderflappagelayout;
