import React from 'react';
import SolutionHeader from './CaseStudyHeader/CaseStudyHeader';
import SolutionBody from './CaseStudyBody/CaseStudyBody'

const CaseStudy = () => {
  return (
    <div>
      <SolutionHeader />
      <SolutionBody />
    </div>
  )
}

export default CaseStudy
