import React from "react";
import Ptype4banner from "../../Component/ProductFeatures/Ptypebarrier/Ptype4banner";
import Ptype from "../../Component/ProductFeatures/Ptypebarrier/Ptype";
const Anprpagelayout = () => {
  return (
    <div>
      <Ptype4banner />
      <Ptype />
    </div>
  );
};

export default Anprpagelayout;
