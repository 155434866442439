// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vega{
    cursor: pointer;
}
@media (max-width: 863px) and (min-width: 768px) { 
    .image-with-text img {
      width: 38px;
    }
    .overlay-text {
      font-size: 18px; /* Adjust font size */
      margin-top: -3px; /* Adjust margins if necessary */
    }
    .image-with-text span {
      font-size: 8px; /* Maintain current font size */
    }
  }

  

  
  
  
  
  


  `, "",{"version":3,"sources":["webpack://./src/Component/ProductFeatures/Shutdoormotor/Shut.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI;MACE,WAAW;IACb;IACA;MACE,eAAe,EAAE,qBAAqB;MACtC,gBAAgB,EAAE,gCAAgC;IACpD;IACA;MACE,cAAc,EAAE,+BAA+B;IACjD;EACF","sourcesContent":[".vega{\n    cursor: pointer;\n}\n@media (max-width: 863px) and (min-width: 768px) { \n    .image-with-text img {\n      width: 38px;\n    }\n    .overlay-text {\n      font-size: 18px; /* Adjust font size */\n      margin-top: -3px; /* Adjust margins if necessary */\n    }\n    .image-with-text span {\n      font-size: 8px; /* Maintain current font size */\n    }\n  }\n\n  \n\n  \n  \n  \n  \n  \n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
