import React from "react";
import Sliding from "../../Component/ProductFeatures/Slidinggateoperator/Sliding";
import Slidingbanner from "../../Component/ProductFeatures/Slidinggateoperator/Slidingbanner";

const Slidinggatelayout = () => {
  return (
    <div>
      <Slidingbanner />
      <Sliding />
    </div>
  );
};

export default Slidinggatelayout;
