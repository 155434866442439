import React from "react";
import CrashBanner from "../../Component/ProductFeatures/Crashratedbarrier/Crash4banner";
import Crash from "../../Component/ProductFeatures/Crashratedbarrier/Crash";
const Crashpagelayout = () => {
  return (
    <div>
      <CrashBanner />
      <Crash />
    </div>
  );
};

export default Crashpagelayout;
