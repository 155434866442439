import React from "react";
import Swing from "../../Component/ProductFeatures/Swinggateoperator/Swing";
import Swingbanner from "../../Component/ProductFeatures/Swinggateoperator/Swingbanner";

const Slidinggatelayout = () => {
  return (
    <div>
      <Swingbanner />
      <Swing />
    </div>
  );
};

export default Slidinggatelayout;
