import React from "react";
import Tripodbanner from "../../Component/ProductFeatures/TripodTurnstile/Tripodbanner";
import Tripodturnstile from "../../Component/ProductFeatures/TripodTurnstile/Tripodturnstile";
const TripodeTurnstileLayout = () => {
  return (
    <div>
      <Tripodbanner />
      <Tripodturnstile />
    </div>
  );
};
export default TripodeTurnstileLayout;
