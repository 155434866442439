import React from "react";
import Bollard from "../../Component/ProductFeatures/Bollard/Bollard";
import BollardBanner from "../../Component/ProductFeatures/Bollard/BollardBanner";
const Bollardpagelayout = () => {
  return (
    <div>
      <BollardBanner />
      <Bollard />
    </div>
  );
};
export default Bollardpagelayout;
