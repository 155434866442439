import React from "react";
import Uvssbanner from "../../Component/ProductFeatures/Uvss/Uvssbanner_";
import Uvss from "../../Component/ProductFeatures/Uvss/Uvss";
const Uvsspagelayout = () => {
  return (
    <div>
      <Uvssbanner/>
      <Uvss/>
    </div>
  );
};

export default Uvsspagelayout;
