// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .button-container {
    padding: 20px;
    border-radius: 8px;
    justify-content: center;
    display: flex;
    gap: 15px;

  }

  .button-custom {
    background-color: white; /* Button white background */
    color: black;
    text-transform: uppercase;
    padding: 10px 20px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .button-custom:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .image-circle {
    background-color: white;
    border-radius: 50%; 
    padding: 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px; 
    height: 60px;
  }
  
  .image-circle img {
    width: 40px; 
    height: 40px;
    border-radius: 50%;
  }`, "",{"version":3,"sources":["webpack://./src/Component/Solution/IotSolution/IotEsolution/IotEsolution.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,SAAS;;EAEX;;EAEA;IACE,uBAAuB,EAAE,4BAA4B;IACrD,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,qDAAqD;EACvD;;EAEA;IACE,sBAAsB;IACtB,yCAAyC;EAC3C;;EAEA;IACE,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":["  .button-container {\n    padding: 20px;\n    border-radius: 8px;\n    justify-content: center;\n    display: flex;\n    gap: 15px;\n\n  }\n\n  .button-custom {\n    background-color: white; /* Button white background */\n    color: black;\n    text-transform: uppercase;\n    padding: 10px 20px;\n    cursor: pointer;\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n  }\n\n  .button-custom:hover {\n    transform: scale(1.05);\n    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);\n  }\n  \n  .image-circle {\n    background-color: white;\n    border-radius: 50%; \n    padding: 10px; \n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 60px; \n    height: 60px;\n  }\n  \n  .image-circle img {\n    width: 40px; \n    height: 40px;\n    border-radius: 50%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
