import React from "react";
import Tyrebanner from "../../Component/ProductFeatures/Tyrekiller/Tyrebanner";
import Tyre from "../../Component/ProductFeatures/Tyrekiller/Tyre";
const Anprpagelayout = () => {
  return (
    <div>
      <Tyrebanner />
      <Tyre />
    </div>
  );
};

export default Anprpagelayout;
