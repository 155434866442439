// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.director-quote {
    text-align: center;
    /* padding: 20px; */
}

.quote-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.quote-text h3 {
    margin: 0;
    text-align: center;
}

.quote-text-bold {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    color: gray;
}

.quote-icon {
    font-size: 24px;
    color: #007bff;
    margin-right: 10px;
}

.quote-content {
    display: inline-block;
}

/* Responsive styles */
@media only screen and (max-width: 600px) {
    .quote-text {
        padding: 10px;
        border-radius: 0;
        box-shadow: none;
        max-width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Component/Quote/Quote.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,yCAAyC;IACzC,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA,sBAAsB;AACtB;IACI;QACI,aAAa;QACb,gBAAgB;QAChB,gBAAgB;QAChB,eAAe;IACnB;AACJ","sourcesContent":[".director-quote {\n    text-align: center;\n    /* padding: 20px; */\n}\n\n.quote-text {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin: 0 auto;\n}\n\n.quote-text h3 {\n    margin: 0;\n    text-align: center;\n}\n\n.quote-text-bold {\n    font-family: Arial, Helvetica, sans-serif;\n    font-weight: 700;\n    color: gray;\n}\n\n.quote-icon {\n    font-size: 24px;\n    color: #007bff;\n    margin-right: 10px;\n}\n\n.quote-content {\n    display: inline-block;\n}\n\n/* Responsive styles */\n@media only screen and (max-width: 600px) {\n    .quote-text {\n        padding: 10px;\n        border-radius: 0;\n        box-shadow: none;\n        max-width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
