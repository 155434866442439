import React from "react";
import Shutdoorbanner from "../../Component/ProductFeatures/Shutdoormotor/Shutdoorbanner";
import Shutdoor from "../../Component/ProductFeatures/Shutdoormotor/Shutdoor";
const Shutdoormotorpagelayout = () => {
  return (
    <div>
      <Shutdoorbanner />
      <Shutdoor />
    </div>
  );
};

export default Shutdoormotorpagelayout;
