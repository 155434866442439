import React from "react";
import Flappbanner from "../../Component/ProductFeatures/FlappBarrier/Flappbanner";
import Flappbarrier from "../../Component/ProductFeatures/FlappBarrier/Flappbarrier";
const FlappbarrierLayout = () => {
  return (
    <div>
      <Flappbanner />
      <Flappbarrier />
    </div>
  );
};
export default FlappbarrierLayout;
