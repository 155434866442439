import React from "react";
import Anprbanner from "../../Component/ProductFeatures/Anprcamera/Anprbanner";
import Anpr from "../../Component/ProductFeatures/Anprcamera/Anpr";

const Anprpagelayout = () => {
  return (
    <div>
      <Anprbanner />
      <Anpr />
    </div>
  );
};

export default Anprpagelayout;
