import React from "react";
import Dfmdbanner from "../../Component/ProductFeatures/DFMD/Dfmdbanner";

import Dfmd from "../../Component/ProductFeatures/DFMD/Dfmd";

const Anprpagelayout = () => {
  return (
    <div>
      <Dfmdbanner />
      <Dfmd />
    </div>
  );
};

export default Anprpagelayout;
