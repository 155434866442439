import React from "react";

import Axelbanner from "../../Component/ProductFeatures/Axlebreaker/Axelbanner";
import Axel from "../../Component/ProductFeatures/Axlebreaker/Axel";
const Anprpagelayout = () => {
  return (
    <div>
      <Axelbanner />
      <Axel />
    </div>
  );
};

export default Anprpagelayout;
