import React from 'react';
import HomePageLayOut from './Layout/HomePageLayOut/HomePageLayOut.jsx';
import RouteComponent from './RouteComponents/RouteComponent.jsx';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function App() {
  return (
    <div>
      <RouteComponent />
    </div>
  );
}
export default App;
