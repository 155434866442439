// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card {
    background-color: white;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    width:300px;
    transition: none;
    border-radius: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/Component/Solution/SolutionProduct/SolutionProduct.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,2CAA2C;IAC3C,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".product-card {\n    background-color: white;\n    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);\n    width:300px;\n    transition: none;\n    border-radius: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
