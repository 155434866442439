import React, { useState } from "react";
import "./PgsEsolution.css";
import AccessController from "./AccessController";
import PaymentStation from "./PaymentStation";
import AccessGate from "./AccessGates";
import SoftwarePackage from "./SoftwarePackages";

const PgsEsolution = () => {
  const [activeComponent, setActiveComponent] = useState("AccessController");

  const renderComponent = () => {
    switch (activeComponent) {
      case "AccessController":
        return <AccessController />;
      case "PaymentStation":
        return <PaymentStation />;
      case "AccessGate":
        return <AccessGate />;
      case "SoftwarePackage":
        return <SoftwarePackage />;
      default:
        return <AccessController />;
    }
  };

  return (
    <>
      <div className="button-wrapper">
        <div className="button-container clickable" onClick={() => setActiveComponent("AccessController")}>
          <p className="mt-2">Access Terminals</p>
        </div>
        <div
          className="button-container clickable"
          onClick={() => setActiveComponent("PaymentStation")}
        >
          <p className="mt-2">Payment Stations</p>
        </div>
        <div
          className="button-container clickable"
          onClick={() => setActiveComponent("AccessGate")}
        >
          <p className="mt-2">Access Gates</p>
        </div>
        <div
          className="button-container clickable"
          onClick={() => setActiveComponent("SoftwarePackage")}
        >
          <p className="mt-2">CMS & Server</p>
        </div>
      </div>

      {/* Content Rendered Here */}
      <div className="content-container">{renderComponent()}</div>
    </>
  );
};

export default PgsEsolution;
