import React from "react";
import Dropbanner from "../../Component/ProductFeatures/Droparmbarrier/Dropbanner";
import Drop from "../../Component/ProductFeatures/Droparmbarrier/Drop";

const Droppagelayout = () => {
  return (
    <div>
      <Dropbanner />
      <Drop />
    </div>
  );
};

export default Droppagelayout;
