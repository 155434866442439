import React from "react";
import Swingflapbanner from "../../Component/ProductFeatures/Swingflap/Swingflapbanner_";
import Swingflap from "../../Component/ProductFeatures/Swingflap/Swingflap";
const Swingflappagelayout = () => {
  return (
    <div>
      <Swingflapbanner />
      <Swingflap />
    </div>
  );
};

export default Swingflappagelayout;
