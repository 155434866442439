// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --imageCount: 4;
  --imageHeight: 100vh;
  --imageWidth: 100vw;
  --imageMargin: 0;
  --imagePadding: 0;
}

.slider-section-container {
  max-width: 100%;
  margin: 0 auto;
}

#slider-section-images {
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--imageCount), var(--imageHeight));
  gap: var(--imageMargin);
  padding-bottom: calc(var(--imageCount) * var(--imagePadding));
  margin-bottom: var(--imageMargin);
}

.slider-section-image {
  position: sticky;
  top: 0;
  height: var(--imageHeight);
  width: var(--imageWidth);
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.slider-section-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
}

.slider-section-image img {
  width: 100%;
  height: 100vh;
  object-fit: fill;
  border-radius: 0;
  /* Removed transition for simple scrolling */
}
`, "",{"version":3,"sources":["webpack://./src/Utils/Slider/Slider.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,0BAA0B;EAC1B,iEAAiE;EACjE,uBAAuB;EACvB,6DAA6D;EAC7D,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,0BAA0B;EAC1B,wBAAwB;EACxB,yCAAyC;EACzC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,4CAA4C;AAC9C","sourcesContent":[":root {\n  --imageCount: 4;\n  --imageHeight: 100vh;\n  --imageWidth: 100vw;\n  --imageMargin: 0;\n  --imagePadding: 0;\n}\n\n.slider-section-container {\n  max-width: 100%;\n  margin: 0 auto;\n}\n\n#slider-section-images {\n  list-style: none;\n  padding-left: 0;\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: repeat(var(--imageCount), var(--imageHeight));\n  gap: var(--imageMargin);\n  padding-bottom: calc(var(--imageCount) * var(--imagePadding));\n  margin-bottom: var(--imageMargin);\n}\n\n.slider-section-image {\n  position: sticky;\n  top: 0;\n  height: var(--imageHeight);\n  width: var(--imageWidth);\n  background-color: var(--background-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1;\n}\n\n.slider-section-image-container {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  border-radius: 15px;\n}\n\n.slider-section-image img {\n  width: 100%;\n  height: 100vh;\n  object-fit: fill;\n  border-radius: 0;\n  /* Removed transition for simple scrolling */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
